import React, { FunctionComponent, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

interface Props {
  header?: string;
  id: string;
}

const Accordion: FunctionComponent<Props> = ({ children, header, id }) => {
  const [expanded, setExpanded] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        aria-controls={id}
        aria-expanded={expanded}
        className="accordion border-b-2 border-green flex text-left w-full"
        id={`${id}-trigger`}
        type="button"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="flex-1 pr-4">
          {header}
        </span>

        <FontAwesomeIcon className={clsx(expanded && 'transform rotate-45', 'transition-all')} icon={faPlus} />
      </button>

      <div
        aria-labelledby={`${id}-trigger`}
        className="accordion-panel"
        id={id}
        ref={contentRef}
        role="region"
        style={{ maxHeight: expanded ? contentRef.current?.scrollHeight || 0 : 0 }}
      >
        <p>{children}</p>
      </div>
    </>
  );
};

export default Accordion;
