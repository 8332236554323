import React, { FunctionComponent } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Accordion from '../components/Accordion';

const Catering: FunctionComponent = () => (
  <Layout>
    <SEO title="Catering" />

    <div className="relative mb-16">
      <div className="hidden sm:block">
        <StaticImage alt="" className="hero-image" src="../images/catering-hero.png" />
      </div>

      <div className="block sm:hidden">
        <StaticImage alt="" src="../images/catering-hero-mobile.png" />
      </div>

      <div className="absolute bg-black bg-opacity-40 inset-0" />

      <div className="absolute left-1/2 min-w-full px-4 text-center text-white top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="font-Parisienne mb-8 text-6xl md:text-7xl xl:text-8xl">
          Event Catering
        </h1>

        <p className="font-medium max-w-md mx-auto text-lg">
          Whether it is a business meeting, a sports celebration party or a
          family get together, we can help with your next event!
        </p>
      </div>
    </div>

    <div className="max-w-md mx-auto px-4 text-center">
      <div className="mb-16">
        <h3 className="font-light text-green text-sm md:text-lg">BREAKFAST OPTIONS</h3>
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Pastry Platter</h2>

        <p className="font-light">
          A variety of muffins, danishes and croissants served with cream cheese, butter and jelly.
        </p>

        <p className="font-bold mt-4">
          $2.49 | Per Person
        </p>
      </div>

      <div className="mb-16">
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Fresh Fruit Platter</h2>

        <p className="font-light">
          Abundance of the freshest seasonal fruits cut and beautifully
          arranged. Fruits that may be includes: Strawberry, orange, kiwi,
          pineapple, watermelon, cantaloupe, honeydew and grapes.
        </p>

        <p className="font-bold mt-4">
          $3.49 | Per Person
        </p>
      </div>
    </div>

    <div className="bg-green divider mb-16 mx-auto max-w-sm sm:max-w-2xl lg:max-w-4xl" />

    <div className="max-w-md mx-auto px-4 text-center">
      <div className="mb-16">
        <h3 className="font-light text-green text-sm md:text-lg">SALAD OPTIONS</h3>
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Entree Salad</h2>

        <p className="font-light">
          Choice of salad from our menu.
        </p>

        <p className="font-bold mt-4">
          $3.99 | Per Person
        </p>
      </div>
    </div>

    <div className="bg-green divider mb-16 mx-auto max-w-sm sm:max-w-2xl lg:max-w-4xl" />

    <div className="max-w-md mx-auto px-4 text-center">
      <div className="mb-16">
        <h3 className="font-light text-green text-sm md:text-lg">LUNCH OPTIONS</h3>
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Lunch Platter</h2>

        <p className="font-light">
          Sandwiches, wraps or combination from our menu selection.
          Each sandwich is cut and attractively arranged on a platter.
          Each platter includes: Gourmet potato chips, pickles, mayonnaise, mustard on the side.
        </p>

        <p className="font-bold mt-4">
          $8.49 | Per Person
        </p>
      </div>

      <div className="mb-16">
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Lunch Buffet</h2>

        <p className="font-light">
          Sandwiches, wraps or combination from our menu selection.
          Each sandwich is cut and attractively arranged on a platter.
          Each platter includes: Gourmet potato chips, pickles, mayonnaise,
          mustard, w/ choice of salad from our menu.
        </p>

        <p className="font-bold mt-4">
          $12.99 | Per Person
        </p>
      </div>

      <div className="mb-16">
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Lunch Deluxe Buffet</h2>

        <p className="font-light">
          Sandwich, wrap, salad and dessert. It includes: Sandwich,
          wrap, w/ choice of salad from our menu, cookies &amp; brownies,
          gourmet potato chips, pickles, mayonnaise, mustard on the side.
        </p>

        <p className="font-bold mt-4">
          $17.99 | Per Person
        </p>
      </div>
    </div>

    <div className="bg-green divider mb-16 mx-auto max-w-sm sm:max-w-2xl lg:max-w-4xl" />

    <div className="max-w-md mx-auto px-4 text-center">
      <div className="mb-16">
        <h3 className="font-light text-green text-sm md:text-lg">DESSERT OPTIONS</h3>
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Cookies &amp; Brownies Platter</h2>

        <p className="font-bold mt-4">
          $2.99 | Per Person
        </p>
      </div>

      <div className="mb-16">
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Dry Fruits &amp; Nuts</h2>

        <p className="font-bold mt-4">
          $4.99 | Per Person
        </p>
      </div>
    </div>

    <div className="bg-green divider mb-16 mx-auto max-w-sm sm:max-w-2xl lg:max-w-4xl" />

    <div className="max-w-md mx-auto px-4 text-center">
      <div className="mb-16">
        <h3 className="font-light text-green text-sm md:text-lg">BEVERAGE OPTIONS</h3>
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Coffee</h2>

        <p className="font-light">
          Regular or decaf. Assorted flavors.
        </p>

        <p className="font-bold mt-4">
          $15.99 | Per Pot
        </p>
      </div>

      <div className="mb-16">
        <h2 className="font-Parisienne my-6 text-4xl md:text-5xl">Tea</h2>

        <p className="font-light">
          Regular or decaf. Assorted flavors.
        </p>

        <p className="font-bold mt-4">
          $1.49 | Per Person
        </p>
      </div>
    </div>

    <div className="max-w-4xl mx-auto pb-24 px-4">
      <h2 className="font-Parisienne mb-8 text-center text-4xl md:mb-16 md:text-5xl">Frequently Asked Questions</h2>

      <Accordion header="How long have you been in the catering business?" id="faq-1">
        Natural Cafe is a gourmet delicatessen and market here in Arlington,
        and we have been in business since 2014. We decided to do catering services
        so that everyone can experience our quality, organic, gourmet foods at any event
        they plan. We are a full-service catering company that will handle any need you might
        have during your event planning process. Contact us today for more information!
      </Accordion>

      <Accordion header="There is no kitchen where my event is being held. Are you still able to cater to it?" id="faq-2">
        Yes, we can! In addition to what we can prepare at our kitchen,
        we also have a mobile kitchen which allows us to cook some of your
        food right on-site. Please let us know where your event is being held
        when you give us your event details so we can address the logistics and any possible issues.
      </Accordion>

      <Accordion header="What forms of payment do you accept?" id="faq-3">
        We accept cash, personal checks, money orders, cashiers checks,
        debit cards, and all major credit cards. If you have any
        further questions on how to pay for our catering services, please contact us.
      </Accordion>

      <Accordion header="Can I add or change things about the catering menu?" id="faq-4">
        We might be able to change a few things about our catering
        menu if we have the ingredients to do so. If you have any further
        questions regarding our menu, please do not hesitate to contact us.
      </Accordion>

      <Accordion header="How far in advance do I need to book you?" id="faq-5">
        If you know your event date, we always recommend you book to
        save your date as soon as possible. Particular months fill up quickly,
        and weekend days at any time of year are especially likely to fill up.
        Once you have signed up and we have agreed on the price, your date is
        set in stone. If you need to change your event date please contact us as
        soon as possible so that we can make sure we have a spot available.
      </Accordion>

      <Accordion header="Are you able to cater to other parts of Virginia besides Arlington?" id="faq-6">
        Since our deli and market are located in Arlington, VA we
        mainly cater events that are in Arlington or at least around the area.
        We might be able to cater your event depending on how far away it
        is located. Please contact us for more information.
      </Accordion>

      <Accordion header="Can you provide me with any services other than catering?" id="faq-7">
        Here at Natural Cafe, we provide services for catering and food preparation and
        distribution at our market. For catering purposes, we can rent items,
        such as tables, bars, stands, utensils,  etc., specifically for your event. Let us
        know beforehand so that we can have everything prepared.
      </Accordion>

      <Accordion header="Do your services include cleaning during &amp; after the event?" id="faq-8">
        Yes. Our staff maintains all food stations throughout the event to ensure
        cleanliness and will bus tables during the event. Our staff is also responsible
        for cleaning their prep and kitchen area and removing trash. Please note that
        Natural Cafe staff is not responsible for mopping, vacuuming, or cleaning restrooms.
      </Accordion>

      <Accordion header="What is included in your corporate catering orders?" id="faq-9">
        Disposable plates, utensils, napkins &amp; disposable tablecloths. Hot items also
        come with chafers &amp; sternos to keep food hot. Included or added beverages come
        with disposable cups &amp; beverage napkins (&amp; ice, creamers, sweeteners,
        as applicable). Our professional staff will deliver &amp; set up your order, as well
        as return to clean up (unless you prefer drop-off only).
      </Accordion>

      <Accordion header="Why should I choose Natural Cafe for my event?" id="faq-10">
        Natural Cafe is ideal when you want your event to be delicious and
        memorable. Our expert catering staff is passionate about the details—expect
        award-winning food and top-notch customer service. Whether you’re hosting a
        small shower, a large corporate party, holiday events, and more, we deliver
        personal and friendly service as we execute delicious menus that will please all
        of your guests. Our chefs create memorable experiences with fresh, local
        ingredients that elevate the flavor of any dish! Our staff will ensure that
        every need is attended to, so you can enjoy your event stress-free.
      </Accordion>

      <Accordion header="How do I make sure there will be enough food for everyone?" id="faq-11">
        Our professional catering team will be happy to assist you. When you fill out
        our Catering Inquiry form, we ask that you provide how many guests will be
        attending your event. If you need to change anything about your event please
        contact us as soon as possible so we can make sure that we have the appropriate
        amount for all of your guests to enjoy.
      </Accordion>

      <Accordion header="Can you provide for vegetarian and special dietary needs?" id="faq-12">
        We have a wide variety of vegetarian, vegan and other special needs menu items.
        When you contact us to plan your event, we will discuss your dietary needs and suggest
        menu changes that will be suitable for you and your guests. Please tell us if we
        need to consider food allergies.
      </Accordion>

      <Accordion header="Can you provide rental items for my event?" id="faq-13">
        Depending on the event, we arrange for the rental of the usual
        party items such as tables, chairs, linens, china, glassware, and
        silverware. If you need specific rental items, contact us beforehand so
        that we can make the necessary arrangements and rent the proper amount.

      </Accordion>

      <Accordion header="How many servers will be needed at my event?" id="faq-14">
        The server ratio depends on how formal or complicated the
        event is and may be reduced or increased, depending on these factors.
        Using your event details, we will recommend the minimum number of
        servers required based on our experience. Our goal is to make your event
        run smoothly, therefore we have a two server minimum for events.
      </Accordion>
    </div>

    <div className="relative">
      <div className="hidden md:block">
        <StaticImage alt="" className="hero-image" src="../images/catering-footer.png" />
      </div>

      <div className="block md:hidden">
        <StaticImage alt="" className="w-full" src="../images/catering-footer-mobile.png" />
      </div>

      <div className="absolute bg-black bg-opacity-40 inset-0" />

      <div className="absolute left-1/2 min-w-full px-4 text-center text-white top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h3 className="font-light mb-8 uppercase">
          Need to Place an Order?
        </h3>

        <h2 className="font-Parisienne mb-8 text-4xl md:text-6xl">
          Catering Inquiry
        </h2>

        <p className="font-light max-w-md mx-auto text-lg">
          Let us know what you would like for us to prepare and make your event special!
        </p>

        <Link
          className="bg-green inline-block mt-8 px-6 py-3 rounded-md text-white transition-opacity hover:opacity-70"
          to="/contact"
        >
          Place Order
        </Link>
      </div>
    </div>
  </Layout>
);

export default Catering;
